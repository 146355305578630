import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation, ViewChildren, AfterViewInit, QueryList, ElementRef,  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Router, NavigationStart } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';


import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';
import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { AlertService, AlertDialog, DialogType, AlertMessage, MessageSeverity } from 'app/services/alert.service';
import { NotificationService } from 'app/services/notification.service';
import { AppTranslationService } from 'app/services/app-translation.service';
import { AccountService } from 'app/services/account.service';
import { LocalStoreManager } from 'app/services/local-store-manager.service';
import { AppTitleService } from 'app/services/app-title.service';
import { AuthService } from 'app/services/auth.service';
import { ConfigurationService } from 'app/services/configuration.service';
import { Permission } from 'app/models/permission.model';
import { Login2Component } from 'app/main/pages/authentication/login-2/login-2.component';
var alertify: any = require('../assets/scripts/alertify.js');
@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit
{
    isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  appTitle = "Open Class";
  appLogo = " ";
  
  

  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailurs = 0;
  notificationsLoadingSubscription: any;
    fuseConfig: any;
    navigation: any;

    @ViewChildren('loginModal,loginControl')
    modalLoginControls: QueryList<any>;
  
   // loginModal: ModalDirective;
    loginControl: Login2Component;
  
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private authService: AuthService,
        storageManager: LocalStoreManager,
         private toastaService: ToastaService,
         private accountService: AccountService, 
         private alertService: AlertService,
          private notificationService: NotificationService, 
          private appTitleService: AppTitleService,
    
    private translationService: AppTranslationService,
     public configurations: ConfigurationService, 
     public router: Router
    )
    {

        storageManager.initialiseStorageSyncListener();
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }
            });


            this.isUserLoggedIn = this.authService.isLoggedIn;

            // 1 sec to ensure all the effort to get the css animation working is appreciated :|, Preboot screen is removed .5 sec later
            setTimeout(() => this.isAppLoaded = true, 1000);
            setTimeout(() => this.removePrebootScreen = true, 1500);
        
            setTimeout(() => {
              if (this.isUserLoggedIn) {
                this.alertService.resetStickyMessage();
        
                //if (!this.authService.isSessionExpired)
                this.alertService.showMessage("Login", `Welcome back ${this.userName}!`, MessageSeverity.default);
                //else
                //    this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again", MessageSeverity.warn);
              }
            }, 2000);
        
        
            this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
            this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
            this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));
        
            this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;
        
            this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
              this.isUserLoggedIn = isLoggedIn;
        
        
              if (this.isUserLoggedIn) {
                this.initNotificationsLoading();
              }
              else {
                this.unsubscribeNotifications();
              }
        
              setTimeout(() => {
                if (!this.isUserLoggedIn) {
                  this.alertService.showMessage("Session Ended!", "", MessageSeverity.default);
                }
              }, 500);
            });
        
            this.router.events.subscribe(event => {
              if (event instanceof NavigationStart) {
                let url = (<NavigationStart>event).url;
        
                if (url !== url.toLowerCase()) {
                  this.router.navigateByUrl((<NavigationStart>event).url.toLowerCase());
                }
              }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    ngAfterViewInit() : void {

       this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
          controls.forEach(control => {
            if (control) {
              if (control instanceof Login2Component) {
                this.loginControl = control;
               // this.loginControl.modalClosedCallback = () => this.loginModal.hide();
              // this.loginControl.modalClosedCallback();
              }
              else {
                console.log('login expired');
        
              }
            }
          });
        });
        
      }
      logout(): void {
        this.authService.logout();
        this.authService.redirectLogoutUser();
      }

      private unsubscribeNotifications(): void {
        if (this.notificationsLoadingSubscription)
          this.notificationsLoadingSubscription.unsubscribe();
      }


      initNotificationsLoading(): void {

        this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically()
          .subscribe(notifications => {
            this.dataLoadingConsecutiveFailurs = 0;
            this.newNotificationCount = notifications.filter(n => !n.isRead).length;
          },
            error => {
             // this.alertService.logError(error);
    
              if (this.dataLoadingConsecutiveFailurs++ < 20)
                setTimeout(() => this.initNotificationsLoading(), 5000);
              else
                this.alertService.showStickyMessage("Load Error", "Loading new notifications from the server failed!", MessageSeverity.error);
            });
      }
    

      showDialog(dialog: AlertDialog) {

        alertify.set({
          labels: {
            ok: dialog.okLabel || "OK",
            cancel: dialog.cancelLabel || "Cancel"
          }
        });
    
        switch (dialog.type) {
          case DialogType.alert:
            alertify.alert(dialog.message);
    
            break
          case DialogType.confirm:
            alertify
              .confirm(dialog.message, (e) => {
                if (e) {
                  dialog.okCallback();
                }
                else {
                  if (dialog.cancelCallback)
                    dialog.cancelCallback();
                }
              });
    
            break;
          case DialogType.prompt:
            alertify
              .prompt(dialog.message, (e, val) => {
                if (e) {
                  dialog.okCallback(val);
                }
                else {
                  if (dialog.cancelCallback)
                    dialog.cancelCallback();
                }
              }, dialog.defaultValue);
    
            break;
        }
      }
    
    
    
    
    
      showToast(message: AlertMessage, isSticky: boolean) {
    
        if (message == null) {
          for (let id of this.stickyToasties.slice(0)) {
            this.toastaService.clear(id);
          }
    
          return;
        }
    
        let toastOptions: ToastOptions = {
          title: message.summary,
          msg: message.detail,
          timeout: isSticky ? 0 : 4000
        };
    
    
        if (isSticky) {
          toastOptions.onAdd = (toast: ToastData) => this.stickyToasties.push(toast.id);
    
          toastOptions.onRemove = (toast: ToastData) => {
            let index = this.stickyToasties.indexOf(toast.id, 0);
    
            if (index > -1) {
              this.stickyToasties.splice(index, 1);
            }
    
            toast.onAdd = null;
            toast.onRemove = null;
          };
        }
    
    
        switch (message.severity) {
          case MessageSeverity.default: this.toastaService.default(toastOptions); break;
          case MessageSeverity.info: this.toastaService.info(toastOptions); break;
          case MessageSeverity.success: this.toastaService.success(toastOptions); break;
          case MessageSeverity.error: this.toastaService.error(toastOptions); break;
          case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
          case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
        }
      }
      getYear() {
        return new Date().getUTCFullYear();
      }
    
    
      get userName(): string {
        return this.authService.currentUser ? this.authService.currentUser.userName : "";
      }
    
    
      get fullName(): string {
        return this.authService.currentUser ? this.authService.currentUser.fullName : "";
      }
      get userEmail(): string {
        return this.authService.currentUser ? this.authService.currentUser.email : "";
      }
      get phoneNumber(): string 
      {
        return this.authService.currentUser ? this.authService.currentUser.phoneNumber : "";

      }
      get profileImage(): string 
      {
        return this.authService.currentUser ? this.authService.currentUser.profilePics : "";

      }
    
      get currentUser()
      {
        return this.authService.currentUser ? this.authService.currentUser: "";

      }
    
    
      get canViewCustomers() {
        return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewCustomersPermission
      }
    
      get canViewProducts() {
        return this.accountService.userHasPermission(Permission.viewUsersPermission); //eg. viewProductsPermission
      }
    
      get canViewOrders() {
        return true; //eg. viewOrdersPermission
      }
    
}
