import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AlertService, MessageSeverity, DialogType } from '../../../../services/alert.service';
import { AuthService } from '../../../../services/auth.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import { Utilities } from '../../../../services/utilities';
import { UserLogin } from '../../../../models/user-login.model';
import { Router } from '@angular/router';
import {
  AuthService as SocialAuth,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular5-social-login';
import { FacebookService, InitParams, LoginResponse } from 'ngx-facebook';
// import {  MatProgressSpinnerModule, } from 'assets/angular-material-examples/progress-spinner-overview/progress-spinner-overview-example';
// import { MatProgressSpinnerModule } from 'app/app.module';
import Swal from 'sweetalert2';
import { Social } from 'app/models/social-model';



@Component({
  selector: 'login-2',
  templateUrl: './login-2.component.html',
  styleUrls: ['./login-2.component.scss'],
  animations: fuseAnimations
})
export class Login2Component implements OnInit, OnDestroy, OnChanges {
  loginForm: FormGroup;
  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  @Input() isRTL : boolean = false;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;
  ProgressSpinnerOverviewExample: any;
  private authWindow: Window;
  failed: boolean;
  error: string;
  errorDescription: string;
  isRequesting: boolean;
  isFLoading: boolean;
  SocailData: Social;
  isGloading: boolean;
  categories: any = {
    clothing: [],
    shoes: [],
    accessories: [],
    gadgets: []
  }
  slideConfig : any;

  slides : any = [
     {
        img         : "assets/images/h-slider-1.jpg",
        content     : "2019 Latest Collection",
        heading_one : "New Fashion Collection",
       
     },
     {
        img         : "assets/images/h-slider-2.jpg",
        content     : "2019 Latest Collection",
        heading_one : "Summer Time Collection",
  
     }, 
     {
        img         : "assets/images/h-slider-3.jpg",
        content     : "2019 Latest Collection",
        heading_one : "Men's Suiting and Clothing",
        
     }
  ]
 
  products: any;
  allProducts: any;
  newProductsSliderData: any;
 

  rtlSlideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  @Input()
  isModal = false;


  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private alertService: AlertService,
    private authService: AuthService,
    private configurations: ConfigurationService,
    private router: Router,
    private fb: FacebookService,
    private socialAuthService: SocialAuth
    // private ProgressSpinnerOverviewExample
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    let initParams: InitParams = {
      appId: '1935487313420424',
      xfbml: true,
      version: 'v2.8'
    };

    fb.init(initParams);

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.userLogin.rememberMe = this.authService.rememberMe;

    if (this.getShouldRedirect()) {
      this.router.navigate(['/apps/academy']);
      // this.authService.redirectLoginUser();
      // this.modalClosedCallback();
    }
    else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
        if (this.getShouldRedirect()) {
          this.router.navigate(['/apps/academy']);
          // this.authService.redirectLoginUser();
        }
      });
    }
  }


  ngOnDestroy(): void {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }
  ngOnChanges() {
    this.slideConfig = {
       infinite: true,
       slidesToShow: 1,
       slidesToScroll: 1,
       autoplay: true,
       autoplaySpeed: 2000,
       dots: false,
       rtl: this.isRTL,
       responsive: [
          {
             breakpoint: 991,
             settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000
             }
          },
          {
             breakpoint: 768,
             settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000
             }
          },
          {
             breakpoint: 480,
             settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 2000
             }
          }
       ]
    };
 }

  getShouldRedirect() {
    return this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }


  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }


  login(fbtoken) {
    this.isLoading = true;
    this.alertService.startLoadingMessage("", "Attempting login...");

    this.authService.login(this.userLogin.email, this.userLogin.password, this.userLogin.rememberMe)
      .subscribe(
        user => {
          setTimeout(() => {
            // this.alertService.stopLoadingMessage();
            this.isLoading = false;
            this.reset();

            if (!this.isModal) {
              this.alertService.showMessage("Login", `Welcome ${user.userName}!`, MessageSeverity.success);
            }
            else {
              this.alertService.showMessage("Login", `Session for ${user.userName} restored!`, MessageSeverity.success);
              setTimeout(() => {
                this.alertService.showStickyMessage("Session Restored", "Please try your last operation again", MessageSeverity.default);
              }, 500);

              this.closeModal();
            }
          }, 500);
        },
        error => {

          this.alertService.stopLoadingMessage();

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
            this.offerAlternateHost();
          }
          else {
            let errorMessage = Utilities.findHttpResponseMessage("error_description", error);

            if (errorMessage)
            // alert("Unable to login" + errorMessage)
            {
              Swal({
                type: 'error',
                title: 'Unable to login',
                text: errorMessage,
                footer: '<a  href="/pages/auth/forgot-password-2">Forgot Password?</a>'
              });

              //this.alertService.showStickyMessage("Unable to login", errorMessage, MessageSeverity.error, error);
            }
            else {
              Swal({
                type: 'error',
                title: 'Unable to login',
                text: 'An error occured whilst logging in, please try again later.\nError: ' + Utilities.getResponseBody(error),
                footer: '<a class="forgot-password" [routerLink]="/pages/auth/forgot-password-2">Forgot Password?</a>'
              })
            }
            // alert("An error occured whilst logging in, please try again later.\nError: ")
            //this.alertService.showStickyMessage("Unable to login", "An error occured whilst logging in, please try again later.\nError: " + Utilities.getResponseBody(error), MessageSeverity.error, error);
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
  }

  loginWithFacebook(): void {
    this.isFLoading = true;
    /*this.authService.login('kenny@webhostingng.org_123456', 'openclass', 'password', this.userLogin.rememberMe, '1234567')
    .subscribe(
      nuser => {
        this.isFLoading = false;
      }
    );
    */

    this.fb.login()
      .then((response: LoginResponse) =>
        // alert(JSON.stringify(response));
        this.authService.facebookLogin(response.authResponse.accessToken, this.userLogin.rememberMe)
          .subscribe(

            user => {
              alert(JSON.stringify(user.facebookId));
              // this.login(response.authResponse.accessToken);
              setTimeout(() => {
                // this.login(user.facebookId);
                this.authService.login(user.userName + '|' + user.facebookId, 'openclass', this.userLogin.rememberMe)
                  .subscribe(
                    nuser => {
                      this.isFLoading = false;
                    }
                  );
                this.isFLoading = false;
              }, 500);
            },

            error => {
            }
          )

      )
      .catch((error: any) => console.error(error));

  }

  public socialSignIn(socialPlatform: string) {

    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      this.isFLoading = true;
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      this.isGloading = true;
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {

        this.authService.socialLogin(userData)
          .subscribe(

            user => {
              // alert(JSON.stringify(user.facebookId));
              // this.login(response.authResponse.accessToken);
              setTimeout(() => {
                // this.login(user.facebookId);
                this.authService.login(user.userName + '|' + user.authId + '_' + user.provider, 'openclass', this.userLogin.rememberMe)
                  .subscribe(
                    nuser => {
                      this.isFLoading = false;
                    }
                  );
                this.isFLoading = false;
              }, 500);
            },

            error => {
            }
          )
        // alert(socialPlatform + " sign in data : " + JSON.stringify(userData))
        //  console.log(socialPlatform + " sign in data : " + JSON.stringify(userData));
        // Now sign-in with userData


      }
    );
  }


  offerAlternateHost() {

    /*if (Utilities.checkIsLocalHost(location.origin) && Utilities.checkIsLocalHost(this.configurations.baseUrl)) {
      this.alertService.showDialog("Dear Developer!\nIt appears your backend Web API service is not running...\n" +
        "Would you want to temporarily switch to the online Demo API below?(Or specify another)",
        DialogType.prompt,
        (value: string) => {
          this.configurations.baseUrl = value;
          this.alertService.showStickyMessage("API Changed!", "The target Web API has been changed to: " + value, MessageSeverity.warn);
        },
        null,
        null,
        null,
        this.configurations.fallbackBaseUrl);
    } */
  }





  launchFbLogin(): void {
    // launch facebook login dialog
    this.authWindow = window.open('https://www.facebook.com/v2.11/dialog/oauth?&response_type=token&display=popup&client_id=1935487313420424&display=popup&redirect_uri=http://localhost:4200/pages/auth/facebook-login&scope=email', null, 'width=600,height=400');
  }
  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }

}
